.App {
  text-align: center;
}

.App-logo {
  height: 15vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.header-text {
  font-style: italic;
  margin-bottom: 0.1vmin;
}
.subHeaderText {
  margin-top: 0.1vmin;
  margin-bottom: 0.1vmin;
}
.functionsText {
  margin-top: 0.1vmin;
  font-size: calc(4px + 2vmin);
  color: grey;
  font-style: italic;
}
/* 
.HeaderText {
  fontSize: '30';
  fontWeight: '600';
  fontStyle: 'italic';
}
.subHeaderText {
  fontSize: 20;
  /* color: colors.headerText, */
  /* fontWeight: '400';
  fontStyle: 'italic';
} */